@import 'variables/index';
@import 'base/index';
@import 'carousel/carousel';

$green: #079247;

body {
    font-family: 'Fredoka One', cursive;
    color:#333;
    position: relative;
}

p {
    font-family: 'Nunito', sans-serif;
    font-size:1.1rem;
}

.hlt-logo {
    max-height: 80px;
    transition: all 300ms;
}

.hlt-logo-sm {
    max-height: 40px !important;
}

.hlt-pattern {
   background: url('/imgs/pattern.svg') 0 0;
}

.hlt-fnt-s{
    font-size:4.5rem
}

.hlt-banner {
    background-image: url('../imgs/banner.jpg');
    background-attachment: fixed;
    background-position: 0 0;
    margin-top: 90px;

    &-txt {
        text-shadow: 0 0 10px #333;
        font-size: 4.5rem;
    }
}

.hlt-bg-1{
    background: url('../imgs/bg__1.jpg') 0 0 no-repeat;
    background-size: cover;
}

.hlt-text-green {
    color: $green;
}
.hlt-text-red{
    color: #d62027;
}
.hlt-text-yellow{
    color: #fcee21;
}

.hlt-shadow {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}

.hlt-top-green{
    background: url('../imgs/top_green.png') 0 0 repeat-x;
    padding-top: 28px;
}

.hlt-top-white{
    background: url('../imgs/top_white.png') 0 0 repeat-x;
    margin-top: -28px;
}

.hlt-bg-green {
    background-color: $green;
}

.hlt-bg-grey {
    background-color: #ebebeb;
}

.hlt-bottle{
    max-height: 400px;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .hlt-fnt-s{
        font-size:2.5rem
    }
    .vh-100 {
        height: 100% !important;
        padding-top: 30px;
        padding-bottom:30px;
    }
  }